import React from 'react';
import PropTypes from 'prop-types';
import Landing from './landing/Landing';
import withConfig from './data/withConfig';
import withMedia from './data/withMedia';
// import Announcement from './announcement/Announcement';
// import TopAnnouncement from './announcement/TopAnnouncement';
import Header from './header/Header';
import About from './about/About';
import Offers from './offers/Offers';
import Team from './team/Team';
import ContactSection from './ContactSection';
import Gallery from './gallery/GallerySection';
import Footer from './Footer';
import Testimonials from './testimonials/Testimonials';

function Home(props) {
  const { isMobile } = props;
  // const [open, setOpen] = React.useState(true);

  return (
    <>
      {/* <TopAnnouncement /> */}
      {/* {open && <Announcement setOpen={setOpen} />} */}
      <Header
        isMobile={isMobile}
        logoColor={isMobile ? '#fff' : '#000'}
        color="#fff"
      />
      <Landing {...props} />
      <About {...props} />
      <Offers {...props} />
      <Team {...props} />
      <Gallery />
      <Testimonials {...props} />
      <ContactSection {...props} />
      <Footer {...props} />
    </>
  );
}

Home.propTypes = {
  isMobile: PropTypes.bool.isRequired,
};

export default withMedia(withConfig(Home));
