import React from 'react';
import { Link } from 'gatsby';
import { Link as ALink } from 'react-scroll';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Button from '../Button';

const Wrapper = styled.header`
  box-sizing: border-box;
  width: 500px;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: ${({ isMobile }) => (isMobile ? '0 5%' : '0')};
  align-items: ${({ isMobile }) => (isMobile ? 'center' : 'flex-start')};

  @media (max-width: 600px) {
    margin: 20px 0;
  }
`;

const Header = styled.h1`
  box-sizing: border-box;
  font-size: ${({ isMobile }) => (isMobile ? '35px' : '65px')};
  margin: 10px 0;
  color: ${({ color }) => color};
  line-height: 1.2;
`;

const SubHeader = styled.p`
  box-sizing: border-box;
  font-size: ${({ isMobile }) => (isMobile ? '20px' : '32px')};
  line-height: 1.3;
  color: #484848;
  margin: 0;
  font-weight: 500;
`;

const ButtonSection = styled.div`
  box-sizing: border-box;
  line-height: 1.2;
  margin: 30px 0;
  display: flex;
  width: 450px;
  max-width: 100%;
  flex-direction: ${({ isMobile }) => (isMobile ? 'column' : 'row')};
  justify-content: space-between;
  align-items: center;

  @media (max-width: 600px) {
    margin: 0;
    margin-top: 20px;
  }
`;

function LandingTextBox({ title, subTitle, isMobile, color }) {
  return (
    <Wrapper isMobile={isMobile} color={color}>
      <div>
        <Header isMobile={isMobile}>{title}</Header>
        <SubHeader isMobile={isMobile}>{subTitle}</SubHeader>
      </div>

      <ButtonSection isMobile={isMobile}>
        <a href="https://form.downswoodalpacas.co.uk/">
          <Button primary isMobile={isMobile} label="book now" />
        </a>
        <ALink to="about" smooth offset={-80} style={{ width: '100%' }}>
          <Button isMobile={isMobile} label="more info" />
        </ALink>
      </ButtonSection>
    </Wrapper>
  );
}

LandingTextBox.propTypes = {
  title: PropTypes.string.isRequired,
  subTitle: PropTypes.string.isRequired,
  isMobile: PropTypes.bool.isRequired,
  color: PropTypes.string.isRequired,
};

export default LandingTextBox;
