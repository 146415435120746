import React from 'react';
import { Link } from 'gatsby';
import { Link as ALink } from 'react-scroll';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import withConfig from '../data/withConfig';

const Menu = styled.div`
  position: fixed;
  transition: transform 420ms;
  transform: ${({ menu }) => (!menu ? 'translateX(100%)' : 'translateX(0)')};
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  background: #008489;
  box-shadow: -2px 0 15px rgba(0, 0, 0, 0.2);
`;

const TopSection = styled.div`
  height: 80px;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 0 5%;
`;

const Nav = styled.nav`
  display: flex;
  flex-direction: column;
  padding: 20px 10%;

  a {
    font-size: 20px;
    color: #fff;
    height: 70px;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    text-decoration: none;
    outline: none;
  }

  a:last-child {
    height: 60px;
    position: absolute;
    bottom: 0;
    left: 0;
    background: #fff;
    color: #000;
    justify-content: center;
  }
`;

function MobileMenu({ menu, setMenu, header }) {
  return (
    <Menu menu={menu}>
      <TopSection>
        <button
          type="button"
          style={{
            background: 'none',
            border: 'none',
            outline: 'none',
          }}
          onClick={() => setMenu(!menu)}
        >
          <i
            style={{
              fontSize: 35,
              color: '#fff',
            }}
            className="material-icons"
          >
            close
          </i>
        </button>
      </TopSection>
      <Nav>
        {header.menuItems.slice(0, header.menuItems.length - 1).map(item => (
          <ALink to={item.link} offset={-80} onClick={() => setMenu(false)}>
            {item.name}
          </ALink>
        ))}

        <a href="https://form.downswoodalpacas.co.uk/">Contact</a>
      </Nav>
    </Menu>
  );
}

MobileMenu.propTypes = {
  menu: PropTypes.bool.isRequired,
  header: PropTypes.shape().isRequired,
  setMenu: PropTypes.func.isRequired,
};

export default withConfig(MobileMenu);
