import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Button = styled.button`
    height: 45px;
    width: 150px;
    background: #fc0;
    border: none;
    font-size: 14px;
    font-weight: 700;
    opacity: 1;
    position: relative;
    cursor: pointer;
`;

const NonHover = styled.span`
    z-index: 5;
    padding-top: 2px;
    font-weight: 600;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    position: absolute;
    letter-spacing: 2px;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const Hover = styled.span`
    z-index: 1;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    background: #fff;
    width: ${({ hover }) => (hover ? '100%' : '0%')};
    transition: width 300ms;
`;

function SlideButton({
  label, setInfoOpen, hoverFunc, buttonOneHover,
}) {
  return (
    <Button
      type="button"
      onClick={() => setInfoOpen(true)}
      onMouseEnter={() => hoverFunc(true)}
      onMouseLeave={() => hoverFunc(false)}
    >
      <Hover hover={buttonOneHover} />
      <NonHover>
        {label.toUpperCase()}
      </NonHover>
    </Button>
  );
}

SlideButton.propTypes = {
  label: PropTypes.string.isRequired,
  setInfoOpen: PropTypes.func.isRequired,
  hoverFunc: PropTypes.func.isRequired,
  buttonOneHover: PropTypes.func.isRequired,
};

export default SlideButton;
