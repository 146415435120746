import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Map from './Map';
import Separator from '../Separator';

const LocationWrapper = styled.div`
  padding-top: 20px;
  width: 950px;
  max-width: 95%;
`;

const Content = styled.div`
  width: 100%;
  display: flex;
  flex-direction: ${({ isMobile }) => (isMobile ? 'column' : 'row')};
  margin: 40px 0;

  h4 {
    margin: 10px 0;
  }

  p {
    margin: 0;
    margin-bottom: 10px;
  }
`;

function Location({
  isMobile, subTitle2, address, phone, email,
}) {
  return (
    <LocationWrapper name="location">
      <h2>{subTitle2}</h2>
      <Separator color="#fc0" />
      <Content isMobile={isMobile}>
        <Map />
        <div style={{ padding: isMobile ? '40px 0' : '0 0 0 20px' }}>
          <h2 style={{ marginTop: 0, marginBottom: 20 }}>Address</h2>
          <address>
            <Separator color="#fc0" />
            { address.map(each => <p key={each}>{each}</p>) }
            <h4>Phone:</h4>
            <p>{ phone }</p>
            <h4>Email:</h4>
            <p>{email }</p>
          </address>
        </div>
      </Content>
    </LocationWrapper>
  );
}

Location.propTypes = {
  isMobile: PropTypes.bool.isRequired,
  subTitle2: PropTypes.string.isRequired,
  address: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default Location;
