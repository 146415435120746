import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import SectionTitle from '../SectionTitle';
import AboutUs from './AboutUs';
import Location from './Location';
import withConfig from '../data/withConfig';

const Section = styled.section`
  width: 100%;
  margin: 0 auto;
  padding: 25px 0;
  background: #f2f2f2;
  color: #484848;
`;

const Content = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

function About({ isMobile, about }) {
  return (
    <Section name="about">
      <SectionTitle
        title={about.title}
        color="#484848"
        background="#fc0"
      />
      <Content>
        <AboutUs
          isMobile={isMobile}
          {...about}
        />
        <Location
          isMobile={isMobile}
          {...about}
        />
      </Content>
    </Section>
  );
}

About.propTypes = {
  isMobile: PropTypes.bool.isRequired,
  about: PropTypes.shape(PropTypes.string).isRequired,
};

export default withConfig(About);
