import React from 'react';

import Layout from '../components/layout';
import SEO from '../components/seo';
import Home from '../components/Home';


const keywords = [
  'react',
  'alpacas',
  'downswood',
  'alpaca walks',
  'wiltshire activities',
  'arts and crafts',
  'alpaca arts and crafts',
];

const IndexPage = () => {
  return (
    <Layout>
      <SEO title="Home" keywords={keywords} />
      <Home />
    </Layout>
  );
};

export default IndexPage;
