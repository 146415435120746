import React from 'react';
import styled from 'styled-components';
import { Link as ALink } from 'react-scroll';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInstagram, faFacebook, faYoutube } from '@fortawesome/free-brands-svg-icons';

const Container = styled.section`
    background: #fff;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
`;

const Content = styled.div`
    width: 100%;
    padding: 50px 5%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
`;

const Links = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 25px;
`;

const CopyWrite = styled.div`
  font-size: 14px;
`;

const Logo = styled.h1`
  margin: 0;
  font-size: ${({ isMobile }) => (isMobile ? '15px' : '25px')};
  color: ${({ scrolled, isMobile }) => (scrolled || isMobile ? '#fff' : '#000')};
  font-family: Lobster, serif;
  font-weight: 400;
  transition: font-color 420ms;
`;

const Socials = styled.div`
    width: 100%;
    display: flex;
    padding: 0 5%;
    align-items: center;
    justify-content: space-between;
    height: 75px;
    background: #eaeaea;
`;

const Icons = styled.div`
  display: flex;
  align-items: center;
`;

const IconWrapper = styled.a`
  margin-left: 30px;
  font-size: 20px;
  display: flex;
  align-items: center;
`;

const links = [
  {
    text: 'About',
    link: 'about',
  },
  {
    text: 'Where are we?',
    link: 'location',
  },
  {
    text: 'Offers',
    link: 'offers',
  },
  {
    text: 'Meet the team',
    link: 'team',
  },
  {
    text: 'Gallery',
    link: 'gallery',
  },
];

function Footer() {
  return (
    <Container>
      <Content>
        <Logo>Downswood Alpacas</Logo>
        <Links>
          <small>LINKS</small>
          { links.map(each => (
            <ALink
              to={each.link}
              smooth
              offset={-80}
              style={{
                color: '#000',
                textDecoration: 'none',
                cursor: 'pointer',
              }}
            >{each.text}
            </ALink>
          ))}
        </Links>
      </Content>
      <Socials>
        <CopyWrite>&copy; Downswood Alpacas 2019 - 01380 812174</CopyWrite>
        <Icons>
          <IconWrapper>
            <FontAwesomeIcon icon={faInstagram} />
          </IconWrapper>
          <IconWrapper href="https://www.facebook.com/DownswoodAlpacas/" target="_blank">
            <FontAwesomeIcon icon={faFacebook} />
          </IconWrapper>
          <IconWrapper>
            <FontAwesomeIcon icon={faYoutube} />
          </IconWrapper>
        </Icons>
      </Socials>
    </Container>
  );
}

export default Footer;
