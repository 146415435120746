import React from 'react';
import PT from 'prop-types';
import styled from 'styled-components';

import withConfig from '../data/withConfig';
import MessageBubble from './MessageBubble';
import SectionTitle from '../SectionTitle';

const Container = styled.section`
    width: 100%;
    padding-top: 25px;
    padding-bottom: 75px;
    background: #f2f2f2;
    color: #484848;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const TestimonialContainer = styled.div`
    display: flex;
    justify-content: center;

    @media (max-width: 900px) {
      width: 100%;
      flex-direction: column;
      align-items: center;
    }
`;

const Quote = styled.div`
    display: flex;
    width: 100%;
    flex-direction: column;
    margin: 10px 20px;
`;

const Customer = styled.div`
    display: flex;
`;

const Avatar = styled.div`
    height: 50px;
    width: 50px;
    border-radius: 50%;
    background: #008489;
    margin-left: 10px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;

    h2 {
      color: #fff;
      margin: 0;
      margin-bottom: 2px;
    }
`;

const Info = styled.div`
    display: flex;
    flex-direction: column;
    margin-left: 20px;

    p {
        margin: 0;
        font-weight: 700;
    }

    small: {
        margin: 0;
    }
`;

function Testimonials({ testimonials }) {
  return (
    <Container>
      <SectionTitle
        title="Testimonials"
        color="#484848"
        background="#fc0"
      />
      <TestimonialContainer>
        { testimonials.map(each => (
          <Quote>
            <Customer>
              <Avatar>
                {/* <img src={each.imgSrc} alt="" height="100%" width="100%" /> */}
                <h2>{each.name[0].toUpperCase()}</h2>
              </Avatar>
              <Info>
                <p>{each.name}</p>
                <small>{each.date}</small>
              </Info>
            </Customer>
            <MessageBubble>
              {each.text}
            </MessageBubble>
          </Quote>
        ))}
      </TestimonialContainer>

    </Container>
  );
}

Testimonials.propTypes = {
  testimonials: PT.shape().isRequired,
};

export default withConfig(Testimonials);
