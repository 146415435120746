import React from 'react';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import SlideButton from './SlideButton';

const OverlayWrapper = styled.div`
  z-index: 11;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #24292e;
  padding: 20px 15px;
  padding-right: 35px;
  display: flex;
  text-align: left;
  flex-direction: column;
  transform: ${({ infoOpen }) =>
    infoOpen ? 'translateY(0)' : 'translateY(100%)'};
  transition: transform 420ms;

  h2 {
    margin: 10px 0;
  }

  ul {
    list-style: none;
    line-height: 1.2;
    list-style-position: outside;
  }

  li {
    margin: 5px 0;
    text-align: justify;
    font-size: 15px;
  }

  ul li:before {
    content: '✓ ';
  }
`;

const ButtonWrapper = styled.div`
  width: 100%;
  position: absolute;
  height: 120px;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

function Overlay({
  index,
  title,
  included,
  infoOpen,
  setInfoOpen,
  buttonTwoHover,
  setButtonTwo,
}) {
  return (
    <OverlayWrapper infoOpen={infoOpen}>
      <h2 style={{ textAlign: 'center' }}>What&apos;s included?</h2>
      <ul>
        {included.map(each => (
          <li>{each}</li>
        ))}
      </ul>
      <ButtonWrapper>
        <a href="https://form.downswoodalpacas.co.uk/">
          <SlideButton
            label="select option"
            hoverFunc={setButtonTwo}
            buttonOneHover={buttonTwoHover}
            setInfoOpen={setInfoOpen}
          />
        </a>
      </ButtonWrapper>
    </OverlayWrapper>
  );
}

Overlay.propTypes = {
  index: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  included: PropTypes.arrayOf().isRequired,
  infoOpen: PropTypes.bool.isRequired,
  setInfoOpen: PropTypes.func.isRequired,
  buttonTwoHover: PropTypes.bool.isRequired,
  setButtonTwo: PropTypes.func.isRequired,
};

export default Overlay;
