import React from 'react';
import PT from 'prop-types';
import styled from 'styled-components';
import SectionTitle from '../SectionTitle';
import withConfig from '../data/withConfig';


const Container = styled.section`
  width: 100%;
  padding-top: 25px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ImageWrapper = styled.div`
  width: 960px;
  max-width: 95%;
  display: flex;
  flex-wrap: wrap;
  padding-bottom: 75px;
`;

function Gallery({ gallery }) {
  const { images } = gallery;
  return (
    <Container name="gallery">
      <SectionTitle
        title="Gallery"
        color="#484848"
        background="#fc0"
      />
      <ImageWrapper>
        { images.slice(0, 3).map(src => (
          <img
            src={src.get(400, 600)}
            alt=""
            style={{ margin: 10, width: 300, height: 200 }}
          />
        ))}
        { images.slice(3, 7).map(src => (
          <img
            src={src.get(600, 440)}
            alt=""
            style={{ margin: 10, width: 220, height: 300 }}
          />
        ))}
      </ImageWrapper>
      {/* <ImageGallery items={images} /> */}
    </Container>
  );
}

Gallery.propTypes = {
  gallery: PT.shape().isRequired,
};

export default withConfig(Gallery);
