import React, { useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import ProgressiveImage from 'react-progressive-image';
import LazyLoad from 'react-lazyload';
import Overlay from './Overlay';
import SlideButton from './SlideButton';

const Card = styled.div`
    box-sizing: border-box; 
    width: 350px;
    height: 500px;
    margin: 10px;
    border: none;
    max-width: 100%;
    padding: 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    position: relative;
    color: #fff;
    overflow: hidden;
    transition: border-top-left-radius 420ms, border-top-right-radius 420ms;
    border-top-left-radius: ${(({ infoOpen, index }) => (infoOpen && !index ? '40px' : '0px'))};
    border-top-right-radius: ${(({ infoOpen, index }) => (infoOpen && index === 3 ? '40px' : '0px'))};
    cursor: pointer;
`;

const Content = styled.div`
  z-index: 10;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const ButtonWrapper = styled.div`
  box-sizing: border-box;
  width: 100%;
  position: absolute;
  height: 120px;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const TitleWrapper = styled.div`
  position: absolute;
  top: 0;
  width: 100%;
  height: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ImageWrapper = styled.div`
  z-index: 1;
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  transition: transform 420ms;
  transform: ${({ hover }) => (hover ? 'scale(1.1)' : 'scale(1)')};
  :after {
    content: ' ';
    z-index: 2;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(180deg, rgba(0,0,0,0.1) 50%, rgba(0,0,0,.7) 90%);
  }
`;

const Image = styled.div`
  height: 100%;
  width: 100%;
  background-image: ${({ src }) => `url("${src}")`};
  background-size: cover;
  background-position: center;
`;

function PictureCard({
  index, title, info, included, img, isMobile, isTablet,
}) {
  const [hover, setHover] = useState(false);
  const [buttonOneHover, setButtonOneHover] = useState(false);
  const [buttonTwoHover, setButtonTwoHover] = useState(false);
  const [infoOpen, setInfoOpen] = useState(false);

  const setButtonTwo = (input) => {
    setButtonTwoHover(input);
  };

  return (
    <Card
      className="picture-card"
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      index={index}
      isMobile={isMobile}
      isTablet={isTablet}
      infoOpen={infoOpen}
    >
      <Overlay
        index={index}
        title={title}
        included={included}
        infoOpen={infoOpen}
        setInfoOpen={setInfoOpen}
        setButtonTwo={setButtonTwo}
        buttonTwoHover={buttonTwoHover}
      />

      <Content>
        <TitleWrapper>
          <h3 style={{ fontSize: 16, lineHeight: 1.2 }}>{title}</h3>
        </TitleWrapper>
        <h1 style={{ fontSize: 35, lineHeight: 1.2 }}>{info[0]}</h1>
        { info.slice(1).map(each => <p style={{ fontSize: 25, margin: '5px 0' }}>{each}</p>) }
        <ButtonWrapper>
          <SlideButton
            label="more info"
            hoverFunc={setButtonOneHover}
            buttonOneHover={buttonOneHover}
            setInfoOpen={setInfoOpen}
          />
        </ButtonWrapper>
      </Content>

      <ImageWrapper hover={hover}>
        <LazyLoad height={200} offset={100}>
          <ProgressiveImage src={img} placeholder="">
            {(src, loading) => (loading ? (
              <div style={{
                height: '100%',
                width: '100%',
                background: '#484848',
              }}
              />
            ) : (
              <Image
                src={src}
              />
            ))}
          </ProgressiveImage>
        </LazyLoad>
      </ImageWrapper>
    </Card>
  );
}

PictureCard.propTypes = {
  index: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  included: PropTypes.arrayOf().isRequired,
  info: PropTypes.string.isRequired,
  img: PropTypes.string.isRequired,
  isMobile: PropTypes.bool.isRequired,
  isTablet: PropTypes.bool.isRequired,
};

export default PictureCard;
