import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import ProgressiveImage from 'react-progressive-image';
import LazyLoad from 'react-lazyload';
import Separator from '../Separator';

const ImageWrapper = styled.div`
    width: 950px;
    max-width: 95%;
    overflow: hidden;
`;

const TextWrapper = styled.div`
    width: 950px; 
    max-width: 95%;
`;

const Text = styled.p`
  margin: 10px 0;
  text-align: justify;
`;

const placeholder = () => <div style={{ width: '100%', height: '100%', background: '#000' }} />;

function AboutUs({
  isMobile, subTitle1, text1, desktopImage, mobileImage,
}) {
  const img = isMobile ? mobileImage : desktopImage;

  return (
    <>
      <ImageWrapper>
        <LazyLoad height={200} offset={20}>
          <ProgressiveImage src={img} placeholder="">
            {(src, loading) => (loading ? placeholder : (
              // eslint-disable-next-line jsx-a11y/media-has-caption
              <video
                controls
                style={{ border: '1px solid #000', marginBottom: 50, outline: 'none' }}
                src="https://res.cloudinary.com/dgdniqfi9/video/upload/v1570552002/alpacas/alpacaprojectfinished2.mov"
                alt="video demoing Alpaca life"
                width="100%"
              />
            ))}
          </ProgressiveImage>
        </LazyLoad>
      </ImageWrapper>

      <TextWrapper>
        <h2>{subTitle1}</h2>
        <Separator color="#fc0" />
        <div>
          {text1.map(text => <Text>{text}</Text>)}
        </div>
      </TextWrapper>
    </>
  );
}

AboutUs.propTypes = {
  isMobile: PropTypes.bool.isRequired,
  subTitle1: PropTypes.string.isRequired,
  text1: PropTypes.string.isRequired,
  mobileImage: PropTypes.string.isRequired,
  desktopImage: PropTypes.string.isRequired,
};

export default AboutUs;
