import React, { useState, useEffect } from 'react';
import { Link } from 'gatsby';
import { Link as ALink } from 'react-scroll';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import withConfig from '../data/withConfig';
import MobileMenu from './MobileMenu';

const HeaderWrapper = styled.header`
  z-index: 100;
  width: 100%;
  height: 80px;
  position: fixed;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 50px 0 5%;
  background: ${({ scrolled }) => (scrolled ? '#008489' : 'transparent')};
  border-bottom: ${({ scrolled }) =>
    scrolled ? '1px solid rgba(0,0,0,0.4)' : 'transparent'};
  transition: all 500ms;

  @media (max-width: 900px) {
    top: 100px;
  }

  @media (max-width: 600px) {
    padding: 0 5%;
  }
`;

const MenuBar = styled.div`
  color: ${({ textColor }) => textColor};
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  text-decoration: none;
`;

const LogoWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 60px;
  color: ${({ scrolled, logoColor }) => (scrolled ? '#fff' : logoColor)};
`;

const HeaderText = styled.h1`
  margin: 0;
  font-size: ${({ isMobile }) => (isMobile ? '30px' : '35px')};
  color: ${({ scrolled, isMobile }) =>
    scrolled || isMobile ? '#fff' : '#000'};
  font-family: Lobster, serif;
  font-weight: 400;
  transition: font-color 420ms;
`;

const MobileMenuButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  background: none;
  border: none;
  outline: 0;
`;

function Header({ logoColor, isMobile, color, header }) {
  const [scrolled, setScrolled] = useState(false);
  const [menu, setMenu] = useState(false);

  const scrollListener = () => {
    if (window.scrollY) {
      return setScrolled(true);
    }
    return setScrolled(false);
  };

  useEffect(() => {
    document.addEventListener('scroll', scrollListener);
    return () => document.removeEventListener('scroll', scrollListener);
  }, []);

  const textColor = scrolled ? '#fff' : color;
  return (
    <HeaderWrapper scrolled={scrolled}>
      <MenuBar>
        <LogoWrapper logoColor={logoColor} scrolled={scrolled}>
          <HeaderText isMobile={isMobile} scrolled={scrolled}>
            {header.title}
          </HeaderText>
        </LogoWrapper>
        {isMobile ? (
          <MobileMenuButton onClick={() => setMenu(!menu)}>
            <i
              style={{ marginTop: 3, fontSize: 35, color: '#fff' }}
              className="material-icons"
            >
              menu
            </i>
          </MobileMenuButton>
        ) : (
          <nav
            style={{
              width: 500,
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            {header.menuItems.map(each => {
              if (each.external) {
                return (
                  <a
                    href="https://form.downswoodalpacas.co.uk/"
                    style={{ color: 'white' }}
                  >
                    {each.name}
                  </a>
                );
              }

              return (
                <ALink
                  to={each.link}
                  smooth
                  offset={-80}
                  style={{
                    color: textColor,
                    textDecoration: 'none',
                    cursor: 'pointer',
                  }}
                >
                  {each.name}
                </ALink>
              );
            })}
          </nav>
        )}
      </MenuBar>
      <MobileMenu menu={menu} setMenu={setMenu} />
    </HeaderWrapper>
  );
}

Header.propTypes = {
  color: PropTypes.string.isRequired,
  header: PropTypes.shape().isRequired,
  logoColor: PropTypes.string.isRequired,
  isMobile: PropTypes.bool.isRequired,
};

export default withConfig(Header);
