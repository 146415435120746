import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import PictureCard from './pictureCard/PictureCard';
import SectionTitle from '../SectionTitle';
import withConfig from '../data/withConfig';

const OffersWrapper = styled.section`
  width: 100%;
  margin: 0 auto;
  padding: 25px 0;
  background: #fff;
`;

const Content = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Text = styled.div`
  font-size: 20px;
  width: 900px;
  max-width: 90%;
  text-align: center;
  line-height: 1.5;
`;

const OfferCardSection = styled.div`
  /* width: 1050px; */
  max-width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
`;

const CardWrapper = styled.div`
  display: flex;
  flex-direction: ${({ isMobile, isTablet }) =>
    isMobile || isTablet ? 'column' : 'row'};
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 95%;
  margin: 40px 0;
`;

function Offers(props) {
  const { isMobile, isTablet, offers } = props;
  const { cards } = offers;
  return (
    <OffersWrapper name="offers">
      <Content>
        <SectionTitle title="What We Offer" color="#000" background="#008489" />
        <Text>
          <p>
            We are able to offer a range of different alpaca experiences
            including woodland walks, crafting, day visits and now bespoke hours
            geared towards younger alpaca enthusiasts! Woodland walk Children
            over 12 yrs may lead an alpaca on their own, but all children
            between the ages of 8-16 must be supervised by an adult. Children
            8-11 yrs can share an alpaca with an adult on a walk Children under
            8 can join in the walk accompanied by a non-leading adult, but
            please bear in mind that the terrain can be a bit rough in places!
          </p>

          <p>
            Please wear sturdy shoes or boots – alpacas have sharp toes and may
            accidentally tread on your foot! Conditions can be wet underfoot and
            the ground is uneven. Should you have any mobility problems or want
            to bring a wheelchair we can tailor a visit for you.
          </p>
        </Text>
        <OfferCardSection>
          <CardWrapper isMobile={isMobile} isTablet={isTablet}>
            {cards.map((each, index) => (
              <PictureCard
                key={each.title}
                index={index}
                {...each}
                {...props}
              />
            ))}
          </CardWrapper>
        </OfferCardSection>
      </Content>
    </OffersWrapper>
  );
}

Offers.propTypes = {
  isMobile: PropTypes.bool.isRequired,
  isTablet: PropTypes.bool.isRequired,
  offers: PropTypes.shape(PropTypes.array).isRequired,
};

export default withConfig(Offers);
