import React from 'react';
import PropTypes from 'prop-types';

function Separator({ color }) {
  return (
    <div style={{
      width: 50,
      height: 3,
      background: color,
      marginBottom: '1.45rem',
    }}
    />
  );
}

Separator.propTypes = {
  color: PropTypes.string.isRequired,
};

export default Separator;
