import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const Section = styled.div`
  z-index: 4;
  width: 100%;
  margin: 40px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
`;

const Header = styled.h1`
  color: ${({ color }) => color};
  line-height: 1;
  font-size: 50px;
  margin-bottom: 20px;

  @media(max-width: 900px) {
    font-size: 30px;
  }
`;

const Divider = styled.div`
  background: ${({ background }) => background};
  width: 80px;
  height: 5px;
`;

function SectionTitle({ title, color, background }) {
  return (
    <Section>
      <Header color={color}>
        {title}
      </Header>
      <Divider background={background} />
    </Section>
  );
}

SectionTitle.propTypes = {
  title: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
  background: PropTypes.string.isRequired,
};

export default SectionTitle;
