import React from 'react';

function Map() {
  return (
    <div>
      <div>
        <iframe
          title="map"
          width={800}
          height={500}
          id="gmap_canvas"
          src="https://maps.google.com/maps?q=downswood%20alpacas&t=&z=13&ie=UTF8&iwloc=&output=embed"
          frameBorder={0}
          scrolling="no"
          marginHeight={0}
          marginWidth={0}
        />
        <br />
      </div>
    </div>
  );
}

export default Map;
