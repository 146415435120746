import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';
import Button from './Button';

const Section = styled.section`
  min-height: 350px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #008489;

  h2 {
    padding: 0 5%;
    text-align: center;
    color: #fff;
    font-size: 35px;
    margin-bottom: 40px;
  }
`;

function ContactSection() {
  return (
    <Section>
      <h2>Get in touch to book your alpaca walk now</h2>
      <a href="https://form.downswoodalpacas.co.uk/">
        <Button
          specificColor="#000"
          specificBackground="#00848"
          label="Book Now"
        />
      </a>
    </Section>
  );
}

export default ContactSection;
