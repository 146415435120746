import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import ProgressiveImage from 'react-progressive-image';
import LandingTextBox from './LandingTextBox';

const PlaceHolder = styled.div`
    background-color: #eaeaea;
    height: 100%;
    width: 100%;
`;

const Hero = styled.section`
    height: 100vh;
    width: 100%;
    display: flex;
    position: relative;

    @media (max-width: 600px) {
      height: auto;
    }
`;

const PhotoSection = styled.div`
    position: absolute;
    width: 1000px;
    height: 100vh;
    right: 0;
    z-index: 3;
    background-size: cover;
    background-position: top;
    overflow: hidden;
    :after {
      content: '';
      position: absolute;
      width: 100%;
      height: 100vh;
      top: 0;
      left: 0;
      background: linear-gradient(180deg, rgba(0,0,0,0.4) 10%, rgba(0,0,0,0) 60%);
    }
`;

const Photo = styled.div`
  background: ${({ src }) => `url("${src}")`};
  background-size: cover;
  background-position: top;
  height: 100vh;
`;

const MobilePhotoSection = styled.div`
  height: 280px;
  width: 100%;
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  :after {
      content: '';
      position: absolute;
      width: 100%;
      height: 100vh;
      top: 0;
      left: 0;
      background: linear-gradient(180deg, rgba(0,0,0,0.4) 10%, rgba(0,0,0,0) 60%);
    }
`;

const PhotoOverlay = styled.div`
    z-index: 80;
    position: absolute;
    height: 100vh;
    width: 100%;
    right: 0;
    top: 0;
    background: ${({ background }) => `linear-gradient(115deg, 
    ${background} 30%, rgba(0,0,0,0) 30.1%, 
    rgba(0,0,0,0) 80%, ${background} 80.1%)`};
`;

const TextSection = styled.div`
    z-index: 10;
    width: 500px;
    display: flex;
    justify-content: center;
    margin-top: ${({ isMobile }) => (isMobile ? '280px' : 0)};
    align-items: ${({ isMobile }) => (isMobile ? 'center' : 'center')};
    margin-left: ${({ isMobile }) => (isMobile ? 0 : '5%')};
    max-width: 100%;
    text-align: ${({ isMobile }) => (isMobile ? 'center' : 'left')};
`;

const placeholder = (
  <PlaceHolder />
);

function Landing(props) {
  const { isMobile, landing } = props;
  const {
    title, subTitle, background, backgroundImage, backgroundImageMob, isTablet,
  } = landing;

  return (
    <Hero>
      <TextSection isMobile={isMobile}>
        <LandingTextBox
          color={isMobile ? '#fff' : '#000'}
          title={title}
          subTitle={subTitle}
          isMobile={isMobile}
        />
      </TextSection>
      {!isMobile
        ? (
          <PhotoSection>
            <ProgressiveImage src={backgroundImage} placeholder="">
              {(src, loading) => (loading ? placeholder : (
                <Photo
                  src={src}
                  style={{ opacity: isTablet ? 0.3 : 1 }}
                  alt=""
                  width="100%"
                />
              ))}
            </ProgressiveImage>
            <PhotoOverlay background={background} />
          </PhotoSection>
        )
        : (
          <MobilePhotoSection>
            <ProgressiveImage src={backgroundImageMob} placeholder="">
              {(src, loading) => (loading ? placeholder : (
                <img
                  src={src}
                  style={{
                    margin: 0,
                    padding: 0,
                    position: 'absolute',
                    top: 0,
                  }}
                  alt=""
                  width="100%"
                />
              ))}
            </ProgressiveImage>
          </MobilePhotoSection>

        )
            }
    </Hero>
  );
}

Landing.propTypes = {
  landing: PropTypes.shape().isRequired,
  isMobile: PropTypes.bool.isRequired,

};

export default Landing;
