import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import ProgressiveImage from 'react-progressive-image';

import withConfig from '../data/withConfig';
import PersonCard from './PersonCard';
import SectionTitle from '../SectionTitle';

const placeholder = () => (
  <div style={{
    width: '100%',
    height: '100%',
    background: 'rgb(90,90,90)',
  }}
  />
);

const Section = styled.section`
    z-index: 2;
    width: 100%;
    padding: 25px 0;
    min-height: 750px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    background: #008489;
    position: relative;
    margin: 0 auto;
    padding-bottom: ${({ isMobile, isTablet }) => (isMobile || isTablet ? '250px' : 0)};

    @media (max-width: 900px) {
      :after {
        content: ' ';
        top: 0;
        left: 0;
        position: absolute;
        height: 100%;
        width: 100%;
        background: linear-gradient(180deg, #000 82%, rgba(0,0,0,0.2));
      }
    }
    
`;

const CardWrapper = styled.div`
    width: 1050px;
    max-width: 100%;
    min-height: 400px;
    z-index: 3;
    display: flex;
    align-items: center;
    flex-direction: ${({ isMobile, isTablet }) => (isMobile || isTablet ? 'column' : 'row')};
    justify-content: space-between;
`;

function Team({ isMobile, isTablet, team }) {
  return (
    <Section isMobile={isMobile} isTablet={isTablet} name="team">
      <SectionTitle
        title="Meet The Team"
        color="#fff"
        background="#fc0"
      />
      <CardWrapper isMobile={isMobile} isTablet={isTablet}>
        { team.people.map(each => <PersonCard {...each} isMobile={isMobile} isTablet={isTablet} />)}
      </CardWrapper>
      <div style={isMobile || isTablet ? {
        position: 'absolute',
        bottom: -10,
        left: 0,
        zIndex: 0,
      } : {
        position: 'absolute',
        top: 0,
        left: 0,
        zIndex: 1,
      }}
      >
        <ProgressiveImage src={isMobile ? team.bigImageMob : team.bigImage} placeholder="">
          {(src, loading) => (loading ? placeholder : (
            <img
              src={src}
              alt=""
              width="100%"
              style={{ margin: 0 }}
            />
          ))}
        </ProgressiveImage>
      </div>
    </Section>
  );
}

Team.propTypes = {
  isMobile: PropTypes.bool.isRequired,
  isTablet: PropTypes.bool.isRequired,
  team: PropTypes.shape().isRequired,
};

export default withConfig(Team);
