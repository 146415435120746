import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Btn = styled.button`
    box-sizing: border-box;
    width: ${({ isMobile }) => (isMobile ? '90%' : '200px')};
    color: ${({ color }) => color};
    height: 55px;
    padding-top: 4px;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    opacity: 1;
    margin: ${({ isMobile }) => (isMobile ? '5px 0' : 0)};
    transition: all 420ms;
    background: ${(({ background }) => background)};
    outline: none;
    cursor: pointer;

    :hover {
        border-radius: 0;
        box-shadow: ${({ primary }) => (primary ? 'inset 0 -10px 30px rgba(0,0,0,0.05), inset 0 10px 30px rgba(0,0,0,0.05)' : 'none')}; 
    }
`;

function Button({
  isMobile, label, primary, specificBackground, specificColor,
}) {
  const background = primary ? '#008489' : 'transparent';
  const color = primary ? '#fff' : '#000';
  return (
    <Btn
      type="button"
      isMobile={isMobile}
      color={specificColor || color}
      primary={primary}
      background={specificBackground || background}
    >
      {label.toUpperCase()}
    </Btn>
  );
}

Button.propTypes = {
  isMobile: PropTypes.bool.isRequired,
  label: PropTypes.string.isRequired,
  primary: PropTypes.bool.isRequired,
  specificColor: PropTypes.string.isRequired,
  specificBackground: PropTypes.string.isRequired,
};

export default Button;
