import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Card = styled.div`
    position: relative;
    min-height: 475px;
    width: ${({ isMobile }) => (isMobile ? '90%' : '450px')};
    background: rgba(0,0,0,0.7);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    color: #fff;
    padding: 40px 0 16px 0;
    box-shadow: 0 3px 30px rgba(0,0,0,0.2); 
    border-radius: 5px;
    margin: ${({ isMobile, isTablet }) => (isMobile || isTablet ? '20px 0' : '0')};
    border: ${({ isMobile }) => (isMobile ? '1px solid rgba(255,255,255,0.2)' : 'none')};
`;

const ImageSection = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
`;

const Image = styled.div`
  position: absolute;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  overflow: hidden;
  transform: ${({ move }) => `translateX(${move}%)`};
  box-shadow: 0 4px 15px rgba(0,0,0,0.6);
`;

const TextSection = styled.div`
  margin-top: 120px;
  width: 100%;
  padding: 0 40px;
  text-align: center;
  font-size: 16px;
  line-height: 1.5;

  p {
    text-align: justify;
  }
`;

function PersonCard({
  title, img, text, isMobile, isTablet,
}) {
  return (
    <Card isMobile={isMobile} isTablet={isTablet}>
      <ImageSection>
        { img.map(each => (
          <Image move={each.move}>
            <img src={each.src} alt="" height="100%" />
          </Image>
        )) }
      </ImageSection>
      <TextSection>
        <h3>{title}</h3>
        <p>{text}</p>
      </TextSection>
    </Card>
  );
}

PersonCard.propTypes = {
  title: PropTypes.string.isRequired,
  img: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  isMobile: PropTypes.bool.isRequired,
  isTablet: PropTypes.bool.isRequired,
};

export default PersonCard;
